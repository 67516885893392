@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .custom-clamp {
    @apply text-inherit text-base mt-2 overflow-hidden text-ellipsis transition duration-300;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .text-shadow {
    text-shadow: 2px 2px rgba(0,0,0,.502);
  }
  
  .break-word {
    word-break: break-word;
  }
}

body {
  font-family: "Quicksand", sans-serif;
}

img {
  display: block;
  height: 100%;
  object-fit: cover;
  transition: 0.3s;
  width: 100%;
}

::selection {
    background-color: #10b981;
    color: #fff;
  }

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #ccc;
}
